import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import ProgramLinks from '../components/ProgramLinks'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Cosmetology Instructor Trainee</title>
      <meta name="description" content="Cosmetology Instructor Trainee" />
    </Helmet>

    <BannerLanding
      title="Cosmetology Instructor Trainee"
      subtitle="hair, skin, nails"
      color="cosmetology-instructor"
    />

    <div id="main">
      <section id="one">
        <div className="inner">
          <p>Program Level - Graduate Certificate</p>
          <p>Program Length - 30 weeks</p>
          <header className="major">
            <h2>Performance Objectives</h2>
          </header>
          <ul>
            <li>
              Develop the ability to teach related information, manipulative
              operations and techniques.
            </li>
            <li>
              Develop the ability to use various teaching aids, such as
              instruction sheets, visual aids and tests to provide information
              about the subjects being taught.{' '}
            </li>
            <li>
              Develop the ability to determine the best applications of specific
              teaching techniques used by the vocational teacher in the working
              area and in the classroom.
            </li>
          </ul>
          <header className="major">
            <h2>Skills to Be Developed</h2>
          </header>
          <ul>
            <li>
              Develop, through occupational experiences, personal
              characteristics that will enhance the cosmetology profession by
              producing quality students.
            </li>
            <li>
              Develop lesson plans, outlines, procedures and tests that will
              insure student comprehension and instill the desire to learn in
              even the most difficult student.
            </li>
            <li>
              Development of course content reflecting a comprehensive,
              correlated unit of study.
            </li>
            <li>
              Development of instructional materials that will facilitate quick
              and easy set-up and preparation of a class.
            </li>
            <li>
              Development of supplemental teaching aids that will give the
              student a variety of ideas from which to draw and maintain
              motivation.
            </li>
          </ul>
          <header className="major">
            <h2>Attitudes and Appreciations to be Developed</h2>
          </header>
          <ul>
            <li>
              Learn to appreciate the necessity for constant effort toward the
              achievement of professional competency as a teacher.
            </li>
            <li>
              Realize the importance of developing personal characteristics that
              contribute to success in teaching.
            </li>
          </ul>
        </div>
      </section>
      <ProgramLinks ge="cosmetology-instructor" />
    </div>
  </Layout>
)

export default Landing
